@use 'sass:math';

@font-face {
  font-family: 'icomoon';
  src: url('/assets/fonts/icomoon.eot?3bdubk');
  src:
    url('/assets/fonts/icomoon.eot?3bdubk#iefix') format('embedded-opentype'),
    url('/assets/fonts/icomoon.ttf?3bdubk') format('truetype'),
    url('/assets/fonts/icomoon.woff?3bdubk') format('woff'),
    url('/assets/fonts/icomoon.svg?3bdubk#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
}

@function rem($pixel) {
  @return math.div($pixel, 16) + rem;
}

@media print {
  html {
    font-size: 10px;
  }

  body {
    background: #fff;
    font:
      rem(18) / rem(24) 'Nunito',
      sans-serif;
    min-width: 0 !important;
  }
}

@page {
  size: A4;

  // margin: 10mm;
}

gnx-report-viewer {
  *,
  *:before,
  *:after {
    box-sizing: inherit;
  }

  * {
    max-height: 1000000px;
  }

  .page-number {
    position: absolute;
    right: 10px;
    font-weight: bold;
  }

  .d-none {
    display: none;
  }

  .hidden {
    visibility: hidden;
  }

  .print-title {
    page-break-inside: avoid;
    page-break-after: avoid;

    &:after {
      content: '';
      display: block;
      height: 100px;
      margin-bottom: -100px;
    }
  }

  ul {
    &.blue-list {
      list-style: none;

      li {
        margin-bottom: 21px;

        &:before {
          content: '\2022';
          color: #00729e;
          font-weight: bold;
          display: inline-block;
          width: 1em;
          margin-left: -1em;
        }
      }
    }
  }

  /* HTML5 display definitions
       ========================================================================== */

  /**
   * Correct `block` display not defined for any HTML5 element in IE 8/9.
   * Correct `block` display not defined for `details` or `summary` in IE 10/11
   * and Firefox.
   * Correct `block` display not defined for `main` in IE 11.
   */

  article,
  aside,
  details,
  figcaption,
  figure,
  footer,
  header,
  hgroup,
  main,
  menu,
  nav,
  section,
  summary {
    display: block;
  }

  /**
   * 1. Correct `inline-block` display not defined in IE 8/9.
   * 2. Normalize vertical alignment of `progress` in Chrome, Firefox, and Opera.
   */

  audio,
  canvas,
  progress,
  video {
    display: inline-block; /* 1 */
    vertical-align: baseline; /* 2 */
  }

  /**
   * Prevent modern browsers from displaying `audio` without controls.
   * Remove excess height in iOS 5 devices.
   */

  audio:not([controls]) {
    display: none;
    height: 0;
  }

  /**
   * Address `[hidden]` styling not present in IE 8/9/10.
   * Hide the `template` element in IE 8/9/10/11, Safari, and Firefox < 22.
   */

  [hidden],
  template {
    display: none;
  }

  /* Links
       ========================================================================== */

  /**
   * Remove the gray background color from active links in IE 10.
   */

  a {
    background-color: transparent;
  }

  /**
   * Improve readability of focused elements when they are also in an
   * active/hover state.
   */

  a:active,
  a:hover {
    outline: 0;
  }

  /* Text-level semantics
       ========================================================================== */

  /**
   * Address styling not present in IE 8/9/10/11, Safari, and Chrome.
   */

  abbr[title] {
    border-bottom: 1px dotted;
  }

  /**
   * Address style set to `bolder` in Firefox 4+, Safari, and Chrome.
   */

  b,
  strong {
    font-weight: bold;
  }

  /**
   * Address styling not present in Safari and Chrome.
   */

  dfn {
    font-style: italic;
  }

  /**
   * Address variable `h1` font-size and margin within `section` and `article`
   * contexts in Firefox 4+, Safari, and Chrome.
   */

  h1 {
    font-size: 2em;
    margin: 0.67em 0;
  }

  /**
   * Address styling not present in IE 8/9.
   */

  mark {
    background: #ff0;
    color: #000;
  }

  /**
   * Address inconsistent and variable font size in all browsers.
   */

  small {
    font-size: 80%;
  }

  /**
   * Prevent `sub` and `sup` affecting `line-height` in all browsers.
   */

  sub,
  sup {
    font-size: 75%;
    line-height: 0;
    position: relative;
    vertical-align: baseline;
  }

  sup {
    top: -0.5em;
  }

  sub {
    bottom: -0.25em;
  }

  /* Embedded content
       ========================================================================== */

  /**
   * Remove border when inside `a` element in IE 8/9/10.
   */

  img {
    border: 0;
  }

  /**
   * Correct overflow not hidden in IE 9/10/11.
   */

  svg:not(:root) {
    overflow: hidden;
  }

  /* Grouping content
       ========================================================================== */

  /**
   * Address margin not present in IE 8/9 and Safari.
   */

  figure {
    margin: 1em 40px;
  }

  /**
   * Address differences between Firefox and other browsers.
   */

  hr {
    box-sizing: content-box;
    height: 0;
  }

  /**
   * Contain overflow in all browsers.
   */

  pre {
    overflow: auto;
  }

  /**
   * Address odd `em`-unit font size rendering in all browsers.
   */

  code,
  kbd,
  pre,
  samp {
    font-family: monospace, monospace;
    font-size: 1em;
  }

  /* Forms
       ========================================================================== */

  /**
   * Known limitation: by default, Chrome and Safari on OS X allow very limited
   * styling of `select`, unless a `border` property is set.
   */

  /**
   * 1. Correct color not being inherited.
   *    Known issue: affects color of disabled elements.
   * 2. Correct font properties not being inherited.
   * 3. Address margins set differently in Firefox 4+, Safari, and Chrome.
   */

  button,
  input,
  optgroup,
  select,
  textarea {
    color: inherit; /* 1 */
    font: inherit; /* 2 */
    margin: 0; /* 3 */
  }

  /**
   * Address `overflow` set to `hidden` in IE 8/9/10/11.
   */

  button {
    overflow: visible;
  }

  /**
   * Address inconsistent `text-transform` inheritance for `button` and `select`.
   * All other form control elements do not inherit `text-transform` values.
   * Correct `button` style inheritance in Firefox, IE 8/9/10/11, and Opera.
   * Correct `select` style inheritance in Firefox.
   */

  button,
  select {
    text-transform: none;
  }

  /**
   * 1. Avoid the WebKit bug in Android 4.0.* where (2) destroys native `audio`
   *    and `video` controls.
   * 2. Correct inability to style clickable `input` types in iOS.
   * 3. Improve usability and consistency of cursor style between image-type
   *    `input` and others.
   */

  button,
  input[type='button'],
  input[type='reset'],
  input[type='submit'] {
    cursor: pointer; /* 3 */
  }

  /**
   * Re-set default cursor for disabled elements.
   */

  button[disabled],
  input[disabled] {
    cursor: default;
  }

  /**
   * Remove inner padding and border in Firefox 4+.
   */

  button::-moz-focus-inner,
  input::-moz-focus-inner {
    border: 0;
    padding: 0;
  }

  /**
   * Address Firefox 4+ setting `line-height` on `input` using `!important` in
   * the UA stylesheet.
   */

  input {
    line-height: normal;
  }

  /**
   * It's recommended that you don't attempt to style these elements.
   * Firefox's implementation doesn't respect box-sizing, padding, or width.
   *
   * 1. Address box sizing set to `content-box` in IE 8/9/10.
   * 2. Remove excess padding in IE 8/9/10.
   */

  input[type='checkbox'],
  input[type='radio'] {
    box-sizing: border-box; /* 1 */
    padding: 0; /* 2 */
  }

  /**
   * Fix the cursor style for Chrome's increment/decrement buttons. For certain
   * `font-size` values of the `input`, it causes the cursor style of the
   * decrement button to change from `default` to `text`.
   */
  input[type='number']::-webkit-outer-spin-button {
    height: auto;
  }

  /**
   * 1. Address `appearance` set to `searchfield` in Safari and Chrome.
   * 2. Address `box-sizing` set to `border-box` in Safari and Chrome.
   */

  input[type='search'] {
    box-sizing: content-box; /* 2 */
  }

  /**
   * Remove inner padding and search cancel button in Safari and Chrome on OS X.
   * Safari (but not Chrome) clips the cancel button when the search input has
   * padding (and `textfield` appearance).
   */
  input[type='search']::-webkit-search-decoration {
  }

  /**
   * Define consistent border, margin, and padding.
   */

  fieldset {
    border: 1px solid #c0c0c0;
    margin: 0 2px;
    padding: 0.35em 0.625em 0.75em;
  }

  /**
   * 1. Correct `color` not being inherited in IE 8/9/10/11.
   * 2. Remove padding so people aren't caught out if they zero out fieldsets.
   */

  legend {
    border: 0; /* 1 */
    padding: 0; /* 2 */
  }

  /**
   * Remove default vertical scrollbar in IE 8/9/10/11.
   */

  textarea {
    overflow: auto;
  }

  /**
   * Don't inherit the `font-weight` (applied by a rule above).
   * NOTE: the default cannot safely be changed in Chrome and Safari on OS X.
   */

  optgroup {
    font-weight: bold;
  }

  /* Tables
       ========================================================================== */

  /**
   * Remove most spacing between table cells.
   */

  table {
    border-collapse: collapse;
    border-spacing: 0;
  }

  td,
  th {
    padding: 0;
  }

  img {
    max-width: 100%;
    height: auto;
    vertical-align: top;
  }

  .h,
  .h1,
  .h2,
  .h3,
  .h4,
  .h5,
  .h6,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family: 'Nunito', sans-serif;
    font-weight: bold;
    margin: 0 0 0.5em;
    color: inherit;
  }

  .h1,
  h1 {
    font-size: 30px;
  }

  .h2,
  h2 {
    font-size: 27px;
  }

  .h3,
  h3 {
    font-size: 24px;
  }

  .h4,
  h4 {
    font-size: 21px;
  }

  .h5,
  h5 {
    font-size: 16px;
    color: #537281;
    text-transform: uppercase;
    font-weight: 400;
  }

  .h6,
  h6 {
    font-size: 12px;
  }

  p {
    margin: 0 0 1em;
  }

  a {
    color: #047faa;
    text-decoration: none;
    transition:
      color 0.3s ease-in-out,
      background 0.3s ease-in-out,
      opacity 0.3s ease-in-out;
    cursor: pointer;
  }

  ul:not([class]) {
    line-height: 1.3;
  }

  ul:not([class]) > li {
    margin-bottom: 21px;
  }

  dl {
    display: flex;
    flex-wrap: wrap;
  }

  dl.separator-list {
    margin: 0;
  }

  dl.separator-list dd {
    margin: 0 3px 3px;
  }

  dl.separator-list dd:after {
    content: '|';
    margin-left: 6px;
  }

  dl.separator-list dd:first-of-type {
    margin-left: 0;
  }

  dl.separator-list dd:last-child {
    margin-right: 0;
  }

  dl.separator-list dd:last-child:after {
    display: none;
  }

  dl.separator-list dd:last-child {
    border-right: none;
  }

  address {
    font-style: normal;
  }

  table {
    width: 100%;
    border-collapse: separate;
    margin: 0 0 20px;
  }

  thead {
    text-align: left;
    line-height: 1.8;
  }

  td,
  th {
    padding: 6px 19px;
  }

  th {
    font-size: 12px;
    font-weight: 400;
    border: solid #d5d7db;
    border-width: 1px 0 1px 0;
    padding: 7px 19px;
  }

  th:first-child {
    border-left-width: 1px;
    border-top-left-radius: 5px;
  }

  th:last-child {
    border-right-width: 1px;
    border-top-right-radius: 5px;
  }

  td {
    background-color: #fff;
    border: solid #d5d7db;
    border-width: 0 0 1px;
    line-height: 1.4;
  }

  td:first-child {
    border-left-width: 1px;
  }

  td:last-child {
    border-right-width: 1px;
  }

  tr:last-child td:first-child {
    border-bottom-left-radius: 5px;
  }

  tr:last-child td:last-child {
    border-bottom-right-radius: 5px;
  }

  tr.mark-row {
    color: #e03a39;
  }

  form,
  fieldset {
    margin: 0;
    padding: 0;
    border-style: none;
  }

  input[type='text'],
  input[type='tel'],
  input[type='email'],
  input[type='search'],
  input[type='password'],
  textarea {
    box-sizing: border-box;
    border: 1px solid #999;
    padding: 0.4em 0.7em;
  }

  input[type='text']:focus,
  input[type='tel']:focus,
  input[type='email']:focus,
  input[type='search']:focus,
  input[type='password']:focus,
  textarea:focus {
    border-color: #222;
  }

  input[type='tel']::-webkit-input-placeholder,
  input[type='email']::-webkit-input-placeholder,
  input[type='search']::-webkit-input-placeholder,
  input[type='password']::-webkit-input-placeholder,
  textarea::-webkit-input-placeholder {
    color: #999;
  }

  input[type='text']::-moz-placeholder,
  input[type='tel']::-moz-placeholder,
  input[type='email']::-moz-placeholder,
  input[type='search']::-moz-placeholder,
  input[type='password']::-moz-placeholder,
  textarea::-moz-placeholder {
    opacity: 1;
    color: #999;
  }

  input[type='text']:-moz-placeholder,
  input[type='tel']:-moz-placeholder,
  input[type='email']:-moz-placeholder,
  input[type='search']:-moz-placeholder,
  input[type='password']:-moz-placeholder,
  textarea:-moz-placeholder {
    color: #999;
  }

  input[type='tel']:-ms-input-placeholder,
  input[type='email']:-ms-input-placeholder,
  input[type='search']:-ms-input-placeholder,
  input[type='password']:-ms-input-placeholder,
  textarea:-ms-input-placeholder {
    color: #999;
  }

  input[type='text'].placeholder,
  input[type='tel'].placeholder,
  input[type='email'].placeholder,
  input[type='search'].placeholder,
  input[type='password'].placeholder,
  textarea.placeholder {
    color: #999;
  }

  select {
  }

  textarea {
    resize: vertical;
    vertical-align: top;
  }

  button,
  input[type='button'],
  input[type='reset'],
  input[type='file'],
  input[type='submit'] {
    cursor: pointer;
  }

  section {
    padding: 25px 0;
  }

  section header {
    margin: 0 0 20px;
  }

  section header h1,
  section header h2,
  section header h3 {
    font-family: 'Nunito', sans-serif;
    margin-bottom: 8px;
  }

  [class^='icon-'],
  [class*=' icon-'] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'icomoon', sans-serif !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1; /* Better Font Rendering =========== */
    -moz-osx-font-smoothing: grayscale;
    margin-right: 4px;
  }

  .font-size-10 {
    font-size: 10px;
  }

  @media (min-width: 768px) {
    section header h1,
    section header h2,
    section header h3 {
      line-height: 1;
    }

    dl.separator-list {
      line-height: 1;
    }
  }

  @media (min-width: 1024px) {
    .h1,
    h1 {
      font-size: 36px;
    }

    .h2,
    h2 {
      font-size: 34px;
    }

    .h3,
    h3 {
      font-size: 26px;
    }

    .h4,
    h4 {
      font-size: 32px;
    }

    section header {
      margin: 0 0 28px;
    }

    dl.separator-list dd {
      margin: 0 5px;
    }
  }

  @media print {
    -webkit-print-color-adjust: exact;

    /* Styles for print version */
    ul {
      padding-left: rem(10);
    }

    dd {
      margin: 0 rem(3 p);
    }

    dt {
      margin: 0;
    }

    .section-container,
    .col,
    ul,
    li {
      padding: 0 !important;
    }

    .section-container .text-wrap {
      margin: 0 !important;
    }

    .head-list {
      padding-left: 0 !important;
    }

    dl.separator-list dd:after {
      margin-left: 4px;
    }

    section {
      padding: 0 0 rem(10) 0;

      header {
        margin-top: 0;
        margin-bottom: 0;
      }
    }

    a {
      padding: 0;
    }

    strong {
      display: inline;
    }

    td {
      padding: rem(5);
    }

    td:before {
      display: none;
    }

    table {
      margin-bottom: rem(8);
      page-break-inside: avoid;
    }

    img,
    #nav,
    #sidebar,
    .carousel,
    .search-form,
    .sosial-networks,
    .navigation-panel,
    .icon-info {
      display: none;
    }
    .h1,
    h1 {
      font-size: rem(36) !important;
    }

    .h2,
    h2 {
      font-size: rem(34) !important;
    }

    .h3,
    h3,
    .h4,
    h4 {
      font-size: rem(32) !important;
    }

    .h5,
    h5 {
      font-size: rem(20) !important;
    }

    .h6,
    h6 {
      font-size: rem(16) !important;
    }

    h1,
    .h1,
    h2,
    .h2,
    h3,
    .h3,
    .h4,
    h4,
    .h5,
    h5 {
      page-break-inside: avoid;
      page-break-after: avoid;

      &:after {
        content: '';
        display: block;
        height: 100px;
        margin-bottom: -100px;
      }
    }

    p {
      white-space: pre-line;
    }
  }
}
