$case-status-active: #10c2a0;
$case-status-suspended: #222;
$case-status-resolved: #007da8;
$case-status-review: #b9b016;
$case-status-no-files: #e03a39;

$classifications: (
  'active': $case-status-active,
  'suspended': #222,
  'resolved': #007da8,
  'review': #b9b016,
  'no-files': #e03a39,
);

@mixin generate-case-status-classes {
  @each $cls, $cls-color in $classifications {
    .case-#{$cls}-background {
      background: $cls-color !important;
    }
    mat-select-trigger.case-#{$cls}-color,
    .case-#{$cls}-color .mdc-list-item__primary-text {
      color: $cls-color !important;
    }
  }
}

@include generate-case-status-classes();
