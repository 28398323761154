$gnx-blue: (
  50: #e3f2f9,
  100: #b9dfef,
  200: #8acae4,
  300: #5bb4d9,
  400: #37a4d1,
  500: #18244a,
  600: #128cc3,
  700: #0e81bc,
  800: #0b77b5,
  900: #0665a9,
  A100: #d4ebff,
  A200: #a1d3ff,
  A400: #6ebbff,
  A700: #54b0ff,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #000000,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000,
  ),
);

/* 18244a */

$gnx-material-2nd: (
  50: #e3f2f9,
  100: #b9dfef,
  200: #8acae4,
  300: #5bb4d9,
  400: #37a4d1,
  500: #4eadf2,
  600: #128cc3,
  700: #0e81bc,
  800: #0b77b5,
  900: #0665a9,
  A100: #d4ebff,
  A200: #a1d3ff,
  A400: #6ebbff,
  A700: #54b0ff,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #000000,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000,
  ),
);
