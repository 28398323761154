$content-width: 80%;
$min-content-width: 1256px;
$max-content-width: #{'min(1800px, max(1256px, 100% - 22%))'};
$max-content-full-width: #{'min(1800px, max(1256px, 100% - 20px))'};

$blue1: #007da8;
$blue2: #4eadf2;
$blue3: #187fb4;
$blue4: #0ad2d3;
$blue5: #085a7e;
$blue6: #016997;
$blue7: #289ef4;
$blue8: #192854;
$blue9: #016997;

$color-link-blue: $blue1;
$color-border-light-blue: $blue2;
$color-icon-blue: $blue3;

$blue-dark: #18244a;
$blue-darker: #061133;

$gray: #e9eded;
$gray1: #d5d7db;
$gray2: #f6f8f8;
$gray3: #222;
$gray4: #646464;
$gray5: #f0f4f6;
$gray6: #999;
$gray7: #262626;
$gray8: #373737;
$gray9: #86a2ae;
$gray10: #e3e3e3;

$white: #fff;
$black: #222222;

$background-gray: $gray2;

$red: #e01110;

$green: #21a987;

$breadcrumb-h: 30px;
$breadcrumb-p-t: 8px;

$container-with-breadcrumb-h: calc(100% - #{$breadcrumb-h} - #{$breadcrumb-p-t});
$variant-page-header-min-h: 76px;
$top-nav-h: 74px;

// variant-page-lib
$desktop: 960px;
$desktop-ex: 961px;

$tablet: 1084px;
$tablet-ex: 1085px;

$mobile: 760px;
$mobile-ex: 761px;

$color-blue1: #222222;
$color-blue2: #007da8;
$color-blue3: #435363;
$color-blue4: #015c81;
$color-blue5: #018ac2;
$color-blue6: #04193e;
$color-blue7: #499ed7;
$color-blue8: #248adc;
$color-blue9: #5156c9;
$color-blue10: #408ebd;
$color-blue11: #4dadf2;
$color-gray1: #cfd1d0;
$color-gray2: #6b6f76;
$color-gray3: #cfd1d0;
$color-gray4: #f2f3f5;
$color-gray5: #666666;
$color-gray6: #babbc3;
$color-gray7: #878a91;
$color-gray8: #e4e7ea;
$color-gray9: #545454;
$color-gray10: #7a7b85;
$color-gray11: #979797;
$color-white1: #fff;
$color-white2: #fefefe;
$color-black1: #222222;
$color-black2: #222222;
$color-black3: #02182c;
$color-red1: #ff0000;
$color-red2: #ef285c;
$color-red3: #e90a09;

$color-link: $color-blue2;

$raffle-banner-height: 2.7em;

$settings-container-padding-top: 40px;
$variant-tile-padding-top: 8px;

$tile-hover-box-shadow: 0 1px 3px 1px rgba(39, 50, 56, 0.1);
