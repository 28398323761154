@use '@angular/material' as mat;
@import '@angular/material/prebuilt-themes/deeppurple-amber.css';
@import 'palettes';
@import 'colors';
@import 'variables';
@import 'viewer';
@import 'dialog';
@import 'slide-toggle';
@import 'case-status-colors';
@import '_flex';

@import url('https://fonts.googleapis.com/icon?family=Material+Icons');

.bootstrap-scope {
  @import 'bootstrap/scss/bootstrap';

  a:not([href]) {
    color: $link-color;
    text-decoration: $link-decoration;
    background-color: transparent;
    cursor: pointer;

    @include hover() {
      color: $link-hover-color;
      text-decoration: $link-hover-decoration;
    }
  }
}

$my-custom-level: mat.define-typography-level(
  $font-family: 'Nunito',
  $font-weight: 400,
  $font-size: 1rem,
  $line-height: normal,
  $letter-spacing: normal,
);

$custom-typography: mat.define-typography-config(
  $font-family: 'Nunito',
  $headline-1: $my-custom-level,
  $headline-2: $my-custom-level,
  $headline-3: $my-custom-level,
  $headline-4: $my-custom-level,
  $headline-5: $my-custom-level,
  $headline-6: $my-custom-level,
  $subtitle-1: $my-custom-level,
  $subtitle-2: $my-custom-level,
  $body-1: $my-custom-level,
  $body-2: $my-custom-level,
  $caption: $my-custom-level,
  $button: $my-custom-level,
);

@include mat.core();

$theme-light: mat.define-light-theme(
  (
    color: (
      primary: mat.define-palette($gnx-blue),
      accent: mat.define-palette($gnx-material-2nd),
    ),
    typography: $custom-typography,
  )
);

@include mat.all-component-themes(mat.define-light-theme($theme-light));

.mat-mdc-form-field-infix {
  padding: 0 !important;
}

mat.all html {
  height: 100%;
}

body {
  color: #222;
  height: 100%;
  font-family: 'Nunito', sans-serif;
}

a {
  color: inherit;

  &.disabled {
    pointer-events: none;
  }
}

mat-error {
  color: $error-color;
  font-size: 14px;
}

.popup-backdrop {
  background-color: rgba(0, 0, 0, 0.5);
}

.popup-panel {
  text-align: center;
  /* TODO(mdc-migration): The following rule targets internal classes of dialog that may no longer apply for the MDC version.*/
  mat-dialog-container {
    padding: 0;
  }

  &.support-overflow {
    .mat-mdc-dialog-surface.mdc-dialog__surface {
      overflow: visible;
    }
  }
}

.mdc-snackbar__surface.mdc-snackbar__surface.mdc-snackbar__surface {
  background: unset;
  box-shadow: unset;
}

.mat-mdc-dialog-container .mdc-dialog__content {
  color: inherit !important;
  line-height: inherit !important;
  font-size: inherit !important;
  font-weight: inherit !important;
  letter-spacing: inherit !important;
}

.mdc-dialog__title.mdc-dialog__title:before {
  content: unset;
}

.paddingless-dialog {
  /* TODO(mdc-migration): The following rule targets internal classes of dialog that may no longer apply for the MDC version.*/
  mat-dialog-container {
    padding: 0;
  }
}

.drop-down-menu {
  min-height: unset !important;

  .mat-mdc-menu-item {
    color: #222222;
    font-size: 16px;
    font-weight: bold;
    -webkit-font-smoothing: antialiased;
    line-height: 35px !important;
    height: 35px !important;

    &:focus {
      outline: none;
    }
  }
}

div.region-viewer-tooltip {
  font-size: 14px;
  background-color: #18244a;
  border: 1px solid $border-color-grey;
  overflow: visible;
  position: absolute;
  color: white;
  z-index: 10000;
  transform: translate(-50%, -100%);
  border-radius: 5px;
  padding: 8px;
  max-width: 220px;
  opacity: 0.85;

  &.wide {
    max-width: unset;
  }
}

.blue-md-tooltip .mdc-tooltip__surface {
  background-color: #18244a !important;
  border: 1px solid $border-color-grey;
}

.white-md-tooltip .mdc-tooltip__surface {
  background-color: $white !important;
  color: $header-color-black !important;
  border: 1px solid $border-color-grey;
  box-shadow: 0 0 6px 2px rgba(39, 50, 56, 0.15);
}

.mat-mdc-tooltip .mdc-tooltip__surface {
  font-size: 14px !important;
  overflow: visible;
}

.mat-mdc-tooltip.small-text .mdc-tooltip__surface {
  font-size: 10px !important;
}

.mat-mdc-tooltip.smaller-text .mdc-tooltip__surface {
  font-size: 12px !important;
}

.mat-mdc-tooltip.wide .mdc-tooltip__surface {
  max-width: unset;
}

.mat-mdc-tooltip.border-less .mdc-tooltip__surface {
  border-width: 0;
}

.mat-mdc-tooltip.wider .mdc-tooltip__surface {
  max-width: 400px;
}

.mat-mdc-tooltip.wide-380 .mdc-tooltip__surface {
  max-width: 382px;
}

.mat-mdc-tooltip.wide-480 .mdc-tooltip__surface {
  max-width: 480px;
}

.mat-mdc-tooltip.wide-640 .mdc-tooltip__surface {
  max-width: 640px;
}

.mat-mdc-tooltip.spacious .mdc-tooltip__surface {
  line-height: 18px;
  text-align: center;
  padding: 6px 8px;
}

.mat-mdc-tooltip.multi-line .mdc-tooltip__surface {
  line-height: normal;
  white-space: pre-wrap;
  word-break: break-word;
}

.mat-mdc-tooltip.multi-line2 .mdc-tooltip__surface {
  line-height: normal;
  white-space: pre-line;
  word-break: break-word;
}

.mat-mdc-tooltip.padded .mdc-tooltip__surface {
  padding: 8px 16px !important;
}

.mat-mdc-tooltip.lower .mdc-tooltip__surface {
  position: relative;
  top: 10px;
}

div.mat-mdc-tooltip.center {
  text-align: center;
}

div.mat-mdc-tooltip.black-on-white-tooltip {
  color: #222;
  background-color: #fff;
  border: 1px solid #d5d7db;
}

div.mat-mdc-tooltip.navigation-arrow {
  font-size: 12px !important;
  color: #8b91a4;
  line-height: 14px;
  padding: 12px 0 12px 12px !important;

  &:first-line {
    font-size: 16px !important;
    color: #fff;
  }
}

button.mat-mdc-menu-item,
mat-option.mat-mdc-option {
  min-height: 36px;
}

mat-option.mat-mdc-option,
mat-select.mat-mdc-select {
  font-size: 16px;
}

mat-option.mat-mdc-option.smaller,
mat-select.mat-mdc-select.smaller {
  font-size: 14px;
}

.mat-mdc-menu-content .mat-mdc-menu-item .mat-mdc-menu-item-text.mat-mdc-menu-item-text {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

div.mat-mdc-select-arrow {
  background: url('/assets/svg/arrow-down-blue.svg') no-repeat center;
  width: 11px;
  height: 7px;
  border: none;
  margin: 0 4px;
}

div.mat-mdc-select-arrow svg {
  display: none;
}

div.mat-mdc-select-panel.mat-select-search-panel {
  padding: 0;
}

mat-select-trigger,
span.mat-mdc-select-placeholder {
  -webkit-font-smoothing: subpixel-antialiased;
  color: #222;
}

mat-optgroup.mat-mdc-optgroup {
  font-size: 14px;
  color: #0000008a;
}

/* TODO(mdc-migration): The following rule targets internal classes of tooltip that may no longer apply for the MDC version.*/
.mat-tooltip-hide {
  display: none !important;
}

.overlay {
  transform-origin: center bottom;
  transform: scale(1);
  color: #fff;
  border-radius: 4px;
  text-overflow: ellipsis;
  font-size: 14px;
  padding: 6px 10px;
  margin: 6px;
  white-space: pre-wrap;
  word-break: break-word;

  &.overlay-blue {
    background-color: #18244a;
    border: 1px solid #d5d7db;
  }
}

ng-scrollbar.custom-scrollbar {
  --scrollbar-size: 8px;
  --scrollbar-color: transparent;
  --scroll-view-color: transparent;
  --scrollbar-thumb-color: #016997;
  --scrollbar-thumb-hover-color: #085a7e;
  --scrollbar-container-color: #f6f8f8;
  --scrollbar-padding: 4px;
  --scroll-view-margin: 0;
  --scrollbar-border-radius: 3.5px;
}

ng-scrollbar-y {
  border-left: 1px solid $border-color-grey;
}

ng-scrollbar-x {
  border-top: 1px solid $border-color-grey;
}

div.tribute-container {
  z-index: 1000;

  & > ul {
    margin: 0;
    padding: 10px 0;
    list-style: none;
    border-radius: 5px;
    box-shadow: 0 0 6px 2px rgba(39, 50, 56, 0.1);
    border: solid 1px $border-color-grey;
    background-color: $white;

    & > li {
      padding: 5px 10px;
      font-size: 14px;
      line-height: 16px;

      &.highlight {
        background-color: $background-grey2;
      }
    }
  }
}

.user-mention {
  border-radius: 13px;
  padding: 0 7px;
  color: $header-color-gray;
  background-color: $white;
  cursor: pointer;
}

.fr-element .user-mention {
  color: inherit;
  background-color: $border-color-grey;
}

//div.cdk-overlay-pane,
//div.cdk-overlay-pane > .mat-select-panel-wrap {
//  pointer-events: none !important;
//}
//
//div.cdk-overlay-pane > *,
//div.cdk-overlay-pane > .mat-select-panel-wrap > * {
//  pointer-events: auto !important;
//}

.mat-mdc-select-panel.region-viewer-tracks-select-panel {
  margin-top: 26px;
  max-height: 275px;
  /* TODO(mdc-migration): The following rule targets internal classes of optgroup that may no longer apply for the MDC version.*/
  /* TODO(mdc-migration): The following rule targets internal classes of optgroup that may no longer apply for the MDC version.*/
  mat-optgroup {
    label,
    .mat-mdc-optgroup-label {
      font-size: 10px;
      font-weight: bold;
      line-height: 1;
      height: unset;
      padding-top: 10px;
    }

    &:last-child {
      label,
      .mat-mdc-optgroup-label {
        border-bottom: 1px solid $border-color-grey;
        margin: 0 16px;
      }
    }
  }
}

.mat-mdc-select-panel.region-viewer-filter-select-panel {
  margin-top: 4px;
  max-height: 275px;

  .mat-mdc-option {
    padding: 0 8px;
    font-size: 12px;
    line-height: 24px;
    height: 25px;

    .mat-pseudo-checkbox {
      width: 12px;
      height: 12px;
      border: 1px solid $gray-6;

      &.mat-pseudo-checkbox-checked {
        border-color: transparent;
      }

      &:after {
        height: 4px;
      }
    }

    /* TODO(mdc-migration): The following rule targets internal classes of option that may no longer apply for the MDC version.*/
    .mat-option-text > span {
      display: flex;
      align-items: center;

      .indicator {
        width: 12px;
        height: 12px;
        border-radius: 50%;
        margin-right: 5px;
        display: inline-block;
      }

      img {
        margin-right: 4px;
      }
    }
  }
}

.mat-mdc-select-panel.publications-sort-select-panel {
  margin-top: 6px;
  max-height: 275px;

  img {
    margin-left: 4px;
  }

  input {
    border: 1px solid $border-color-grey;
    border-radius: 17.5px;
    width: 78px;
    display: inline-block;
    outline: none;
    line-height: 22px;
    padding: 0 8px;
    margin: 6px 4px 6px 12px;
  }

  span:last-child:not(:first-child) {
    margin-right: -32px;
  }
}

.mat-mdc-select-panel.publications-add__share-with-select {
  margin-top: 36px;
  margin-left: -15px;
  width: 160px;

  .mat-mdc-option {
    padding: 0 8px;
  }
}

.mat-mdc-select-panel.feed-classification-select-panel {
  margin-top: 42px;
  max-height: 294px;
  font-size: 14px !important;
  border: 1px solid $border-color-grey;

  .mat-mdc-option:hover:not(.mat-mdc-selected),
  .mat-active:not(.mat-selected) {
    background: rgba($blue6, 0.12) !important;
  }

  .mat-mdc-selected:not(.mat-active) {
    background: $blue6 !important;
    color: $white !important;
  }

  .mat-mdc-option:hover.mat-mdc-selected,
  .mat-selected.mat-active {
    background: $blue5 !important;
    color: $white !important;
  }
}

.mat-mdc-select-panel.disease-select-panel {
  margin-top: 34px;
  font-size: 14px !important;
}

.mat-mdc-select-panel.table-column-select {
  max-height: 320px;
}

.mat-mdc-select-panel.assay-editor-select-panel {
  margin-top: 29px;
  font-size: 14px !important;

  .mat-mdc-option {
    height: 32px;
  }
}

.mat-mdc-select-panel.select-with-apply-btn {
  padding-bottom: 0;

  .apply-btn {
    position: sticky;
    bottom: 0;
    height: 40px;
    width: 100%;
    font-size: 14px;
    border-top: 1px solid #e3e3e3;
    color: #086893;
    background: white;
    padding: 0 15px;
    display: flex;
    align-items: center;
    transition: all 0.15s ease;
    cursor: pointer;

    &:hover {
      background: $background-grey;
    }

    &.disabled {
      opacity: 0.5;
      pointer-events: none;
    }
  }
}

.mat-mdc-select-panel label.mat-mdc-optgroup-label {
  height: 2rem;
  line-height: 2rem;
  margin: 0;
  color: #646464;
  font-weight: bold;
}

.taller-panel {
  max-height: 458px !important;

  .mat-mdc-option {
    height: 50px;
  }
}

.mat-autocomplete-panel.cases-autocomplete {
  border-radius: 10px;
  border: solid 3px #4eadf2;
}

.mat-mdc-option.mdc-list-item--disabled.contains-mat-select-search {
  font-family: Nunito, sans-serif;
  height: unset !important;
  min-height: 49px;
  position: sticky !important;
  top: 0;
  background: #fff !important;
  z-index: 1;

  .mat-select-search-no-entries-found {
    font-size: 16px;
    color: #222;
  }

  .mdc-list-item__primary-text {
    opacity: 1;
  }

  .mat-select-search-inner {
    border-color: #d5d7db;
  }
}

.mat-mdc-option .green-highlight {
  background-color: rgba(33, 169, 135, 0.25);
}

.mat-mdc-option .bold-highlight {
  font-weight: bold;
}

.autocomplete-dropdown {
  .option-header {
    height: 28px;
    font-size: 10px;
    line-height: normal;
    display: flex;
    align-items: flex-end;
    padding: 0 16px;
  }

  .mat-mdc-option {
    height: 32px;
    font-size: 14px;
  }
}

.mat-mdc-option.error-phenotype,
.mat-option.error-ethnicity {
  height: 75px;
  /* TODO(mdc-migration): The following rule targets internal classes of option that may no longer apply for the MDC version.*/
  > .mat-option-text {
    white-space: pre;
    line-height: 18px;
    text-align: center;
    font-size: 14px;
  }

  .error-msg {
    color: #222;
    font-size: 14px;
    line-height: 21px;
    display: block;

    &::first-line {
      font-size: 18px;
    }
  }
}

mat-option {
  position: relative;

  .mat-select-search-clear {
    pointer-events: auto;
    min-width: 40px !important;
    width: 40px !important;
    min-height: 40px !important;
    height: 40px !important;
    padding: 0 !important;

    .mat-icon {
      margin: 0;
    }
  }

  .mat-select-search-input {
    background: #fff;
    pointer-events: auto;
    font: inherit;
    border: none;
    outline: 0;
    margin: 0;
    width: 100%;
    max-width: 100%;
  }
}

.mat-mdc-select-panel.rules-select-panel {
  min-width: 360px !important;
  max-width: 360px !important;
  height: 200px;
  transform: translate(6px, 0) !important;
  margin-top: 34px;
  display: flex;
  flex-wrap: wrap;
  padding: 11px !important;
}

.mat-mdc-select-panel.assignee_select_panel {
  padding: 0;
  width: 220px;

  .mat-mdc-option {
    height: 38px;
    min-height: 43px;
    line-height: 2em;
    padding: 0 14px;

    &.short-height {
      height: 32px;
      min-height: 32px;
    }

    &.mat-mdc-selected {
      background-color: initial;
      opacity: 0.5;
    }
  }

  .mdc-list-item__primary-text {
    opacity: 1 !important;
    display: flex;
    justify-content: space-between;
    gap: 2px;
    width: 100%;
  }

  mat-optgroup {
    label,
    .mat-mdc-optgroup-label {
      font-size: 10px;
      color: #222;
      text-transform: uppercase;
      padding: 0 14px 4px;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      line-height: 1em;
      height: 28px;
      min-height: 28px;
    }

    .mat-mdc-option:not(.mat-mdc-option-multiple) {
      padding: 0 14px;
    }
  }
}

.mat-mdc-select-panel.rules-select-panel.ng-animating {
  display: none;
}

.mat-mdc-tooltip.add-publication__tooltip {
  width: 250px;
  background-color: $white;
  color: $header-color-black;
  border: 1px solid $link-color-blue;
  text-align: center;
  font-size: 14px;
  padding: 12px 23px;
}

.gnx-snackbar {
  border-radius: 6px !important;
  background: $link-color-blue;
  border: solid 2px $white;
  min-width: 450px !important;
}

.no-snackbar-container-style {
  background: unset;
  box-shadow: unset;
  padding: 0;
  min-width: unset;
}

/* TODO(mdc-migration): The following rule targets internal classes of menu that may no longer apply for the MDC version.*/
/* TODO(mdc-migration): The following rule targets internal classes of menu that may no longer apply for the MDC version.*/
.report-sections-mat-menu {
  .mat-mdc-menu-content {
    padding: 12px;

    .mat-content-header {
      margin-bottom: 6px;
    }
  }
}

.save-menu-backdrop {
  opacity: 0;
}

snack-bar-container {
  &.clean-snackbar {
    padding: 0;
    background: transparent;
    min-width: initial;
    border-radius: 25px;
  }

  &.success {
    color: #fff;
    background-color: #21a987;
    border-color: #21a987;
    font-size: 16px;

    button {
      min-width: 25px;
      max-height: 25px;
      padding: 0;
      outline: none;
      line-height: 25px;
      /* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version.*/
      .mat-button-wrapper {
        color: #fff;
        font-size: 24px;
        margin-top: -1px;
        display: inline-block;
      }
    }
  }

  &.error {
    color: #fff;
    background-color: #721c24;
    border-color: #721c24;

    button {
      min-width: 25px;
      max-height: 25px;
      padding: 0;
      outline: none;
      line-height: 25px;
      /* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version.*/
      .mat-button-wrapper {
        color: #fff;
        font-size: 24px;
        margin-top: -1px;
        display: inline-block;
      }
    }
  }
}

.cdk-overlay-backdrop.transparent {
  opacity: 0;
}

.gnx-header-hidden-menu {
  &.mat-mdc-menu-panel {
    min-height: 40px;
    max-width: 370px;
  }

  .mat-mdc-menu-item.mat-mdc-menu-item.mat-mdc-menu-item {
    outline: none;
    min-height: unset;
  }

  .mat-mdc-menu-item-text.mat-mdc-menu-item-text.mat-mdc-menu-item-text {
    color: #222222;
    font-size: 16px;
    font-weight: bold;
    -webkit-font-smoothing: antialiased;
    line-height: 35px;
    height: 35px;

    gnx-dots-loader {
      height: 100%;
    }
  }

  a.mat-mdc-menu-item {
    text-decoration: none;

    &[disabled] {
      opacity: 0.5;
    }
  }

  button:disabled {
    opacity: 0.5;
  }

  a.disabled {
    opacity: 0.5;
  }
}

.mat-mdc-input-element,
.mat-mdc-dialog-actions {
  box-sizing: inherit !important;
}

.mat-calendar-body-selected {
  background-color: #4050b4 !important;
}

.mat-calendar-body-in-range {
  &:before {
    background: #d9ddf1 !important;
  }
}

.mat-calendar-body-today {
  border-color: #4050b4 !important;
}

.mat-calendar-body-cell:focus {
  outline: none;
}

.headerless-mat-tab-group.mat-mdc-tab-group > .mat-mdc-tab-header {
  display: none;
}

label.gnx-label {
  display: block;
  line-height: 22px;
  margin-bottom: 6px;
}

label.gnx-label-error {
  color: $error-color;
}

textarea.gnx-input,
input.gnx-input {
  display: block;
  width: 100%;
  height: 38px;
  padding: 6px 10px;
  border-radius: 5px;
  border: solid 1px #d5d7db;
  background-color: #ffffff;
  outline: none;
}

textarea.gnx-input {
  min-height: 38px;
  height: auto;
  resize: none;
}

/* TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version.*/
mat-select.gnx-select {
  border: 1px solid #d5d7db;
  border-radius: 4px;
  font-size: 16px;
  background: #fff;
}

/* TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version.*/
mat-select.gnx-select .mat-mdc-select-trigger {
  padding: 7px 10px;
  height: unset;
}

/* TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version.*/
mat-select.gnx-select:not(.mat-select-disabled) .mat-select-placeholder {
  color: initial;
}

/* TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version.*/

// gnx-anchor is added twice to increase the specificity above the freaking bootstrap reboot styles
a.gnx-anchor.gnx-anchor {
  font-size: 16px;
  color: #0089b3;
  cursor: pointer;
  outline: none;
  white-space: nowrap;

  &:hover {
    color: #0089b3;
    text-decoration: underline;
  }

  &.disabled {
    pointer-events: none;
    opacity: 0.5;
  }

  &.white,
  &.white:hover {
    color: #fff;
  }
}

.gnx-hover-effect:hover {
  filter: brightness(95%);
}

.fr-toolbar.fr-toolbar {
  border-color: $blue3;
  background-color: rgba($blue3, 0.03);
}

.fr-toolbar .fr-command.fr-btn:not(.fr-options) {
  width: 35px;
}

.flat-accordion {
  --mat-expansion-container-shape: 0px;
}
