.w-100 {
  width: 100%;
}

.display-none {
  display: none;
}

.d-inline-flex {
  display: inline-flex;
}

.d-flex-col {
  display: flex;
  flex-direction: column;
}

.justify-content-center {
  justify-content: center;
}

.justify-content-between {
  justify-content: space-between;
}

.justify-content-end {
  justify-content: flex-end;
}

.align-center {
  align-items: center;
}

.position {
  &-relative {
    position: relative !important;
  }
}
