.gnx-dialog {
  &-header {
    background-color: $blue-1;
    color: white;
    height: 44px;
    align-items: center;
    padding: 15px;
    font-size: 20px;
  }

  &-close-btn {
    font-size: 14px;
    cursor: pointer;
  }

  &-body {
    padding: 15px 20px;

    .mat-mdc-list-item.no-checkbox .mat-pseudo-checkbox {
      display: none;
    }
  }

  &-mat-option {
    border-bottom: 1px solid #eeeeee;
  }

  &-ac-header {
    background-color: #dbeaf0;
    height: 40px;
    padding: 8px;
    color: #808080;
    border-radius: 4px 4px 0px 0px;
    display: flex;
    justify-content: space-between;
    font-size: 13px;

    .header-btn {
      background-color: #fff;
      padding: 0px 9px;
      border-radius: 3px;
      border: 1px solid #eeeeee;
      cursor: pointer;
      box-shadow: 1px 2px 2px 0px rgba(0, 0, 0, 0.25);
    }

    &-wrapper.mat-mdc-option {
      padding: 0;
      line-height: unset;
      height: auto;
    }
  }

  &-actions {
    padding-top: 15px;
    display: flex;
    border-top: 1px solid #eeeeee;
    justify-content: flex-end;

    button {
      width: 105px;
      height: 32px;
      border-radius: 5px;
      margin-left: 10px;
      line-height: 100%;
      font-size: 14px;

      &:focus,
      &:active {
        outline: none;
      }

      &:disabled {
        pointer-events: none;
        opacity: 0.6;
      }
    }

    &-primary {
      border: 1px solid $blue-1;
      background-color: $blue-1;
      color: $white;

      &:hover {
        background-color: #096893;
      }
    }

    &-secondary {
      border: 1px solid $blue-1;
      color: $blue-1;
      background-color: $white;

      &:hover {
        background-color: #eeeeee;
      }
    }
  }

  &-loader {
    width: 35px;
    margin-top: -3px;
  }
}

[class*='gnx-dialog-'] {
  font-family: 'PT Sans', sans-serif;
}
