$background-grey: #f6f8f8;
$background-grey2: #f0f4f6;
$background-blue: #18244a;
$border-color-grey: #d5d7db;
$header-color-black: #222222;
$header-color-gray: #7a7b85;

$gray-1: #666;
$gray-2: #435363;
$gray-3: #909090;
$gray-4: #e9eded;
$gray-5: #e3e3e3;
$gray-6: #989b9e;
$gray-7: #373737;
$gray-8: #d8d8d8;
$gray-9: #e3e6ed;
$gray-10: #646464;
$gray-11: #a9b4c5;
$gray-12: #c0c3c8;
$gray-13: #ebecee;

$green: #21a987;
$green-1: #1dc299;

$link-color-blue: #007da8;
$link-color-blue2: #0c83ac;
$blue2: #4eadf2;
$blue3: #187fb4;
$blue4: #0ad2d3;
$blue5: #085a7e;
$blue6: #016997;
$blue7: #1d8fc2;
$blue8: #096893;
$blue9: #e6f0f4;
$blue10: #198abc;

$level-color-na: #8d8d8e;
$level-color-low: #e2451f;
$level-color-medium: #f19a29;
$level-color-high: #1fb57b;
$level-color-full: #1fb57b;

$white: #fff;

$yellow: #f2c834;
$yellow-dark: #b9b016;
$yellow-darker: #96900c;

$error-color: #e03a39;
