/* You can add global styles to this file, and also import other style files */
@import './bootstrap-reboot.css';
@import url('https://fonts.googleapis.com/css?family=Nunito:300,300i,400,400i,500,500i,700,700i');

@import 'params';

html {
  height: 100%;

  body {
    height: 100%;
    font-family: 'Nunito', sans-serif !important;
  }
}

.error-popup-backdrop {
  background-color: black;
  opacity: 0.8 !important;
}

.error-popup-panel {
  text-align: center;
  overflow: auto;
  /* TODO(mdc-migration): The following rule targets internal classes of dialog that may no longer apply for the MDC version. */
  mat-dialog-container {
    padding: 0;
    background: transparent;
  }
}

.franklin-popup-panel {
  @extend .error-popup-panel;
  /* TODO(mdc-migration): The following rule targets internal classes of dialog that may no longer apply for the MDC version. */
  mat-dialog-container {
    background-color: transparent;
  }
}

.cdk-overlay-container {
  overflow: auto;
}

//div.cdk-overlay-pane,
//div.cdk-overlay-pane > .mat-select-panel-wrap {
//  pointer-events: none !important;
//}
//
//div.cdk-overlay-pane > *,
//div.cdk-overlay-pane > .mat-select-panel-wrap > * {
//  pointer-events: auto !important;
//}

.mat-mdc-select-panel.aggregation-rule-select-panel {
  margin-top: 28px;
}

.mat-mdc-select-panel.role-select-panel {
  margin-top: 40px;
}

snack-bar-container.export-snack {
  min-width: 300px;
  max-width: unset;
  margin: 8px;
  border-radius: 5px;
  border: solid 2px $white;
  background-color: $color-link-blue;
  padding: 6px 6px 8px 15px;
}

.full-screen-modal {
  max-width: unset !important;
  width: 100%;
  height: 100%;

  .mat-mdc-dialog-container {
    color: inherit;
    max-width: 100vw;
    max-height: 100vh;
    height: 100%;
    width: 100%;
    border-radius: 0;
    padding: 0;

    .mat-mdc-dialog-content {
      max-height: unset !important;
    }
  }
}

.grey-bg-modal {
  .mat-mdc-dialog-surface.mdc-dialog__surface {
    color: inherit;
    background-color: $gray2;
  }
}

.not-permitted-block {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.33);
  display: flex;
  align-items: center;
  justify-content: center;
}
