$black: #000;
$border-gray: #d5d7db;

$button-down: #095a7e;
$button-hover: #016997;
$button-idle: #1494c9;

$dark-gray: #504d48;
$divider-gray: #979797;
$light-gray: #d5d7db;

$font-color: #222222;
$font-color-disabled: #7b7b7b;

$input-error: #e03a39;

$main-section-background-gray: #f0f4f6;
$main-section-background-white: #f9fbfc;
$main-section-border-gray: #dedede;

$placeholder-gray: #aeafae;

$select-arrow-color: #408ebd;
$select-selected-color: #187fb4;
$select-active-color: #f5f5f5;

$sub-menu-background-gray: #eeefee;
$sub-menu-hover-background-gray: #dfdfdf;
$sub-menu-active-item-background-gray: #dddddd;
$sub-menu-active-item-border-green: #10c2a0;
$sub-menu-border-gray: #e3e3e3;
$sub-menu-blue: #1d5b7d;
$sub-menu-hover-blue: #225e80;
$sub-menu-progress-color: #3d89b8;
$sub-menu-progress-full-color: #10c2a0;

$white: #fff;

// geneview-lab
$black: #000;
$blue: #1d8fc2;
$blue-strongest: #096893;
$blue-stronger: #188abd;
$blue-medium: #48b3e5;
$blue-light: #79bad9;
$blue-lighter: #68bae0;
$blue-lightest: #a6cada;
$blue-1: #007da8;
$blue-2: #187fb4;
$blue-3: #0095bd;
$brown: #860a09;

$gray-lightest: #f5f5f5;
$gray-light: #eee;
$gray: #e3e3e3;
$gray-medium: #d8d8d8;
$gray-dark: #ccc;
$gray-darkest: #b3b3b3;
$gray-header: #9c9a98;
$gray-1: #ddd;
$gray-2: #999;

$font-color: #625e5a;
$font-color-dark: #222;

$menu-gray: #e8e8e8;
$menu-gray-light: #eeeeee;
$menu-gray-darker: #808080;
$menu-blue: #495796;

$red: #f00;
$red-dark: #b32a14;

$score-low: #e64f22;
$score-medium: #f19b2d;
$score-high: #10c2a0;

$yellow: #f2c834;
$yellow-dark: #b9b016;
$yellow-darker: #96900c;

$border-color: #d5d7db;
$background-gray: #f6f8f8;
$link-color: $blue-3;

$green: #1dc299;

$font-family-nunito: 'Nunito', sans-serif;

// samples-lib
$green-2: #b9c816;

$font-color: #625e5a;
$font-color-1: #504e4f;

$orange: #ee9838;

$error-red: #e64f22;

$popup-border: $gray;
$clickable-blue: $blue;
$clickable-hover-blue: $blue-strongest;
