/* TODO(mdc-migration): The following rule targets internal classes of slide-toggle that may no longer apply for the MDC version. */
/* TODO(mdc-migration): The following rule targets internal classes of slide-toggle that may no longer apply for the MDC version. */
mat-slide-toggle.slide-sm {
  height: 19px;
  line-height: 19px;

  .mat-mdc-slide-toggle {
    &-bar {
      width: 31px;
      height: 9px;
      display: block;
    }

    &-thumb {
      width: 100%;
      height: 100%;

      &-container {
        width: 16px;
        height: 16px;
        top: -3.5px;
      }
    }

    &-ripple {
      position: absolute;
      top: calc(50% - 12px);
      left: calc(50% - 12px);
      height: 25px;
      width: 25px;
      border-radius: 100%;
    }
  }
}
